import React from 'react';
import { StyledFactItem } from './styles';
import Slide from 'react-reveal/Slide';

interface FactItemProps {
  label: string;
  info: string;
}

const FactItem = (props: FactItemProps) => {
  return (
    <StyledFactItem>
      <Slide bottom delay={200}>
        <p>{props.label}</p>
        <h2>{props.info}</h2>
      </Slide>
    </StyledFactItem>
  );
};

export default FactItem;
