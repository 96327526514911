import React from 'react';
import FactItem from '../FactItem';
import content from './content';
import { StyledFactsContainer } from './styles';
import Slide from 'react-reveal/Slide';

const FactsContainer = () => {
  const items = content.facts.map((fact, index) => <FactItem key={index} label={fact.label} info={fact.info} />);

  return (
    <Slide bottom>
      <StyledFactsContainer>{items}</StyledFactsContainer>
    </Slide>
  );
};

export default FactsContainer;
