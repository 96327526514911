import styled from 'styled-components';
import { COLOR } from '../../constants';

export const StyledFactItem = styled.div`
  text-align: center;

  h2 {
    font-size: 36px;
    color: ${COLOR.WHITE};
    display: inline;
  }

  p {
    color: ${COLOR.WHITE};
    max-width: 120px;
    margin: 0 auto;
  }
`;
