import React from 'react';
import { Hero, Layout, FullImageContent, Container, Button } from '../../components';
import { graphql } from 'gatsby';
import { HeroHeader } from '../../components/Hero/styles';
import content from './content';
import { PaddingSmall } from '../../styles';
import { COLOR } from '../../constants';
import useIsDesktop from '../../lib/isDesktop';
import FactsContainer from '../../components/FactsContainer';

const IndexPage = ({ data, path }: any) => {
  const isDesktop = useIsDesktop();

  return (
    <Layout isDesktop={isDesktop} path={path} title={content.title}>
      <Hero
        desktopImage={data.heroImage.childImageSharp.fluid}
        mobileImage={data.heroMobileImage.childImageSharp.fluid}
        isDesktop={isDesktop}
      >
        <HeroHeader>{content.heroHeader}</HeroHeader>
        <Button text={content.button} link={content.link} />
      </Hero>
      <FactsContainer />
      <PaddingSmall backgroundcolor={COLOR.WHITE} />
      <FullImageContent content={content.imageContents.about} image={data.mikeImage.childImageSharp.fluid} />
      <FullImageContent
        content={content.imageContents.solutions}
        image={data.littleTheoImage.childImageSharp.fluid}
        reversed={true}
      />
      <FullImageContent content={content.imageContents.insights} image={data.theoImage.childImageSharp.fluid} />
      <PaddingSmall backgroundcolor={COLOR.WHITE} />
    </Layout>
  );
};

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "home/thingco-hero.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 50) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    heroMobileImage: file(relativePath: { eq: "home/thingco-hero-mobile.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 50) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mikeImage: file(relativePath: { eq: "home/mike-home.png" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 50) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    littleTheoImage: file(relativePath: { eq: "home/littletheo-home.png" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 50) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    theoImage: file(relativePath: { eq: "home/theo-home.png" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 50) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default IndexPage;
