interface Fact {
  label: string;
  info: string;
}

interface FactCollection {
  facts: Fact[];
}

const content: FactCollection = {
  facts: [
    { label: 'Data Points Collected', info: '4.8 billion' },
    { label: 'Number of Partners', info: '14' },
    { label: 'Time Spent on 30mph Roads', info: '52%' },
  ],
};

export default content;
