import { IFullImageContent } from '../../components/FullImageContent/index';

interface IHomePageContent {
  title: string;
  heroHeader: string;
  imageContents: {
    about: IFullImageContent;
    solutions: IFullImageContent;
    insights: IFullImageContent;
  };
  button: string;
  link: string;
}

const content: IHomePageContent = {
  title: 'ThingCo Website',
  heroHeader: 'Next generation telematics for your next generation customer',
  imageContents: {
    about: {
      header: 'About ThingCo',
      subheader:
        'Founded by Mike Brockman, ThingCo is focused on developing Next Generation Telematics using the latest technology.',
      description:
        'ThingCo has developed a suite of revolutionary b2b and b2c products and services to address the weaknesses in the current market using a deep understanding of insurance and telematics.',
      button: 'Find Out More',
      link: '/about',
    },
    solutions: {
      header: 'ThingCo Solutions',
      subheader:
        'ThingCo has built a state-of-the-art, fully encrypted, telematics platform using the latest Amazon Web Services IoT serverless technology.',
      description:
        'ThingCo devices take telematics to the next level by deploying AI driven voice in-car real-time. Empowering you with the technology, whilst assisting with the value creation, ThingCo services can be fully tailored to you.',
      button: 'See Our Solutions',
      link: '/solutions',
    },
    insights: {
      header: 'News & Insights',
      subheader: 'ThingCo is regularly featured in the news due to our highly respected and unique service offering.',
      description:
        "We are constantly looking for new ways to create value for our partners and their customers - that's why we're always going the extra mile. Find out more about ThingCo's latest developments, awards, and media presence.",
      button: 'See Our News & Insights',
      link: '/insights',
    },
  },
  button: 'Request a Demo',
  link: '/contact',
};

export default content;
