import styled from 'styled-components';
import { COLOR } from '../../constants';
import { mobileMixin } from '../../styles';

export const StyledFactsContainer = styled.div`
  max-width: max-content;
  margin: 0 auto;
  background-color: ${COLOR.DARKBLUE};
  border-radius: 10px;
  position: relative;
  top: -70px;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  padding: 20px 0;

  div {
    border-right: 1px solid ${COLOR.LIGHTBLUE};
    padding: 0px 65px;
  }

  div:last-of-type {
    border-right: 0;
  }

  ${mobileMixin(`
    max-width: 50%;
    top: -30px;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    padding: 0 70px;

    div {
      border-right: 0;
      border-bottom: 1px solid ${COLOR.LIGHTBLUE};
      padding: 20px 0px;
    }

    div:last-of-type {
      border-bottom: 0;
    }
  `)}
`;
